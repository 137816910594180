import { Cookies } from 'react-cookie';
import { DEBUG_PANEL_API_INTEGRATION, DEBUG_PANEL_API_BASEURL_INT } from 'constants/index';
import { getConfig } from './getConfig';
import { setConfigToProfile } from './helper';
import getProfileSdkResponse from './getProfileSdkResponse';
import { Profile } from '../types/profileType';
import packageJson from '../../package.json';

const cookies = new Cookies();

export const config = {
  ...getConfig(),
  API_KEY: '8fb3991cb3ee44fd984e5df018c25d7c',
  ENVIRONMENT: 'qa',
  BRAND: '',
  COOKIE_DEVICE_ID: 'sample_app_device_id',
  COOKIE_ACCESS_TOKEN: 'sample_app_cookie_access_token',
  COOKIE_PROFILE_ID: 'sample_app_cookie_profile_id',
  SECONDS_FOR_24_HOURS: 60 * 60 * 24,
  FACEBOOK_APP_ID: '441830202634558',
  APPLE_CLIENT_ID: 'com.fox.now.siwa',
  GOOGLE_CLIENT_ID: '408795325691-2ahbapehgrqg94i9h7un5e0mj0gl8c76.apps.googleusercontent.com',
};

export const profileConfig = {
  ...config,
  apiKey: process.env.NEXT_PUBLIC_API_KEY || config.API_KEY,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || config.ENVIRONMENT,
  accessToken: process.env.NEXT_PUBLIC_COOKIE_ACCESS_TOKEN || config.COOKIE_ACCESS_TOKEN,
  profileId: process.env.NEXT_PUBLIC_COOKIE_PROFILE_ID || config.COOKIE_PROFILE_ID,
  appName: 'FoxNews', // required
  appVersion: packageJson.version, // required
  baseUrl:
    cookies.getAll().DEBUG_PANEL_API_BASEURL === DEBUG_PANEL_API_INTEGRATION
      ? DEBUG_PANEL_API_BASEURL_INT
      : process.env.NEXT_PUBLIC_API_BASE_URL || '',
};

export const setUpdatedProfile = async (accessToken?: string): Promise<Profile> => {
  const profile = getProfileSdkResponse(profileConfig);
  return (await setConfigToProfile(await profile, {
    appName: profileConfig.appName,
    appVersion: profileConfig.appVersion,
    accessToken: (accessToken as string) || '',
  })) as Profile;
};

export const analyticsConfig = {
  segmentKey: false,
};

export const isIntegrationApi = (): boolean =>
  cookies.getAll().DEBUG_PANEL_API_BASEURL === DEBUG_PANEL_API_INTEGRATION ||
  process.env.NEXT_PUBLIC_API_BASE_URL === DEBUG_PANEL_API_BASEURL_INT;
