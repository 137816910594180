import React, { useContext } from 'react';
import styled from 'styled-components';
import color from 'styles/colors';
import { ROUTE_HOME, Brand } from 'constants/index';
import { setLoading } from 'context/state/reducer';
import { AppContext } from 'context/GlobalContextProvider';
import { FoxNewsLogo } from '../Icons';
import Link from '../Link';

type TBrandLogo = {
  [key: string]: React.ElementType;
};

const BrandLogo: TBrandLogo = {
  [Brand.FOXNEWS]: FoxNewsLogo,
};

const Logo = BrandLogo[Brand.FOXNEWS];

const Header = (): JSX.Element => {
  const { dispatch } = useContext(AppContext);
  return (
    <StyledHeader>
      <Link className="logo" href={ROUTE_HOME}>
        <Logo onClick={() => dispatch(setLoading(false))} />
      </Link>
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.header`
  z-index: 10;
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0;
  background: ${color.header};
  .logo {
    display: block;
    svg {
      fill: ${color.white};
      width: 100%;
      max-width: 80px;
      max-height: 80px;
    }
  }
`;
