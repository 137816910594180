const color = {
  altoLight: '#d8d8d8',
  azureRadiance: '#007ee4',
  black: '#000000',
  bluePrimary: '#0086bd',
  border: '1px solid #0059B5',
  endeavour: '#0059B5',
  error: '#d0021b',
  errorRed: '#d0021b',
  header: '#000',
  Monza: '#d0021b',
  sanMarino: '#4267b2',
  transparent: 'transparent',
  white: '#fff',
  white05: 'rgba(255, 255, 255, 0.05)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white25: 'rgba(255, 255, 255, 0.25)',
  white50: 'rgba(255, 255, 255, 0.5)',
  body: '#000',
  button: {
    background: 'rgba(255, 255, 255, 0.25)',
    backgroundHover: '#FFFFFF',
    disabled: 'rgba(255, 255, 255, 0.25)',
    hollowBg: 'transparent',
    hollowBorder: '1px solid white',
    text: 'rgba(255, 255, 255, 0.5)',
    textHover: '#000',
  },
};

export default color;
