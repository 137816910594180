import React, { FC } from 'react';

const FoxNewsLogo: FC = () => {
  return (
    <svg viewBox="0 0  80 80" fill="none" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve">
      <path fill="#fff" d="M0 0h80v80H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.458 74.933h2.563v-6.752h-2.563v6.752ZM68.94 72.49c.017-1.744-1.503-2.787-3.955-2.787h-.06c-2.374 0-3.912 1.017-3.912 2.684 0 1.667 1.572 2.633 3.972 2.633 1.99 0 3.417-.633 3.844-1.71h-2.451c-.18.36-.615.547-1.307.547-.906 0-1.392-.376-1.392-1.077h5.261a1.959 1.959 0 0 0 0-.29Zm-11.283-.89c-.035-1.256-.743-1.845-2.631-1.845h-.06a3.108 3.108 0 0 0-2.358.803v-.666h-2.434v4.982h2.563v-2.675c0-.855.367-1.205 1.29-1.205.922 0 1.067.445 1.067 1.359v2.504h2.563V71.6Zm-12.053 0c-.034-1.256-.734-1.845-2.622-1.845h-.026a3.108 3.108 0 0 0-2.357.803v-.666H38.12v4.982h2.562v-2.675c0-.855.368-1.205 1.29-1.205.923 0 1.068.445 1.068 1.359v2.504h2.562V71.6Zm-11.36 3.07c-.222-.086-.342-.351-.342-.796l-.008.009v-1.89a1.812 1.812 0 0 0-.513-1.597 4.653 4.653 0 0 0-2.861-.65c-2.213 0-3.417.539-3.665 1.71h2.35c.102-.368.529-.564 1.264-.564.734 0 1.007.153 1.007.444v.12c0 .384-.965.307-2.767.58-1.375.206-2.067.719-2.067 1.548 0 .829.905 1.436 2.434 1.436a4.404 4.404 0 0 0 2.477-.675c.026.174.069.346.128.512h2.563v-.188Zm-10.916-3.155c0-1.11-.88-1.692-2.512-1.692h-.017a3.322 3.322 0 0 0-2.391.778V68.18h-2.563v6.752h2.563v-2.658c0-.855.367-1.205 1.29-1.205.922 0 1.067.444 1.067 1.359v2.504h2.563v-3.419Zm-15.743.864c0-.906.487-1.436 1.367-1.436.492-.086.976.19 1.153.658h2.563c-.163-1.214-1.538-1.94-3.682-1.94-2.451.034-4.006 1.085-4.006 2.795 0 1.555 1.529 2.564 3.853 2.564 2.143 0 3.544-.744 3.8-2.009h-2.528c-.145.487-.546.727-1.204.727-.854 0-1.316-.453-1.316-1.36Zm-6.021 6.06h76.874V64.766H1.564v13.671Zm27.478-5.095c0 .342.367.59.94.59.862 0 1.477-.325 1.477-.854v-.573a8.8 8.8 0 0 1-1.563.282c-.59.103-.854.213-.854.555Zm37.413-1.512h-2.905c.103-.667.633-1.026 1.452-1.026.82 0 1.435.376 1.453 1.026Z"
        fill="#C20017"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.648 42.32c-4.1 0-6.611 2.52-6.611 5.913 0 2.932 1.708 4.376 5.125 5.401 2.041.616 3.126.565 3.126 1.88 0 .693-.64 1.231-1.708 1.231a2.665 2.665 0 0 1-2.93-2.196l-4.322.949c.734 3.418 3.28 5.452 7.183 5.452a6.832 6.832 0 0 0 5.126-2.11 5.805 5.805 0 0 0 1.511-3.966 5.001 5.001 0 0 0-1.477-3.838h.008c-1.196-1.102-2.562-1.393-4.1-1.828-1.82-.513-2.819-.565-2.819-1.624a1.427 1.427 0 0 1 1.572-1.282 2.358 2.358 0 0 1 2.486 1.803l3.929-1.307a6.04 6.04 0 0 0-6.099-4.479ZM59.014 60.915 63.199 42.9h-3.707l-2.23 10.315L55.086 42.9H51.24l-2.076 10.418-2.16-10.417h-4.588l4.16 18.015h4.186l2.05-10.324 2.11 10.324h4.09ZM41.64 56.583h-7.175v-3.034h5.817v-3.854h-5.817V47.07h7.081v-4.17H29.981v18.015h11.66v-4.333ZM28.845 42.952h-4.442l-.017 8.99-5.364-8.965H13.82v17.947h4.57V50.438l6.124 10.46h4.33V42.953Zm-1.393-3.786h5.715V32.91h5.612v-5.247h-5.612v-2.504H40l-.376-5.265H27.452v19.272Zm12.292-9.546c.089 5.443 4.57 9.785 10.01 9.7h.018c5.434.076 9.904-4.263 9.993-9.7-.221-5.371-4.638-9.611-10.01-9.611-5.373 0-9.79 4.24-10.011 9.61Zm17.34-9.7 5.62 9.264-5.783 9.922h6.142l2.801-4.726 2.87 4.734h6.287l-6.065-9.981 5.458-9.205h-6.227l-2.348 4-2.435-4.008h-6.32Zm19.26 20.177h-.947a62.373 62.373 0 0 1 2.895 6.12h.145v17.69H2.087l10.58-16.528v-5.65h3.315l10.352-16.895v-6.18h4.194L36.37 9.58a61.353 61.353 0 0 1 20.116 9.29h1.452a60.015 60.015 0 0 0-3.195-2.214 61.582 61.582 0 0 0-17.886-7.828l4.783-7.264h36.797v42.893a72.908 72.908 0 0 0-2.092-4.359ZM14.325 1.562H1.563v8.547a61.423 61.423 0 0 1 11.633-2.684l1.128-5.862Zm35.435 33.644a1.76 1.76 0 0 0 1.755-1.638v-8.179a1.837 1.837 0 0 0-1.802-1.769c-.95.041-1.7.819-1.708 1.77v8.178a1.76 1.76 0 0 0 1.755 1.638ZM31.604 1.562h-8.15l-2.05 5.316c2.459-.003 4.914.14 7.355.428l2.845-5.744Zm-10.49 6.154-19.46 56.19h.092L28.375 8.11a60.22 60.22 0 0 0-7.26-.393Zm-8.106.59L1.563 62.907V11.05h.05c3.701-1.281 7.517-2.2 11.395-2.743Z"
        fill="#036"
      />
    </svg>
  );
};

export default FoxNewsLogo;
