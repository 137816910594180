import AppStoreBadge from './AppStoreBadge';
import CheckMark from './CheckMark';
import FoxNewsLogo from './FoxNewsLogo';
import GoogleBadge from './GoogleBadge';
import SearchIcon from './SearchIcon';
import Apple from './Apple';
import Edit from './Edit';
import Facebook from './Facebook';

export { AppStoreBadge, CheckMark, FoxNewsLogo, GoogleBadge, SearchIcon, Apple, Edit, Facebook };
