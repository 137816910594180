import { ErrorResponse } from 'lib/types/accountType';
import { Account } from './state';

export enum ActionType {
  SetLoading = 'SET_LOADING',
  SetLanguageStrings = 'SET_LANGUAGE_STRINGS',
  SetFlowStage = 'SET_FLOW_STAGE',
  SetCodeInfo = 'SET_CODE_INFO',
  SetQRCode = 'SET_QR_CODE',
  SetAccount = 'SET_ACCOUNT',
  SetError = 'SET_ERROR',
  SetIsRegister = 'SET_IS_REGISTER',
  SetIsSocialAuth = 'SET_IS_SOCIAL_AUTH',
}

export interface SetLoading {
  type: ActionType.SetLoading;
  payload: boolean;
}

export interface SetFlowStage {
  type: ActionType.SetFlowStage;
  payload: string;
}

export interface SetCodeInfo {
  type: ActionType.SetCodeInfo;
  payload: AnyObject | undefined;
}

export interface SetQRCode {
  type: ActionType.SetQRCode;
  payload: string;
}

export interface SetAccount {
  type: ActionType.SetAccount;
  payload: Account | undefined;
}

export interface SetLanguageStrings {
  type: ActionType.SetLanguageStrings;
  payload: AnyObject;
}

export interface SetError {
  type: ActionType.SetError;
  payload: ErrorResponse | unknown;
}

export interface SetIsRegister {
  type: ActionType.SetIsRegister;
  payload: Account;
  value: boolean;
}

export interface SetIsSocialAuth {
  type: ActionType.SetIsSocialAuth;
  value: boolean;
  kind: string;
  payload: AnyObject;
}

export type AppActions =
  | SetLoading
  | SetFlowStage
  | SetCodeInfo
  | SetQRCode
  | SetAccount
  | SetLanguageStrings
  | SetError
  | SetIsRegister
  | SetIsSocialAuth;
